<template>
  <div class="product_detil">
    <!-- 顶部导航 -->
    <!-- 固定顶部,样式写在app上了 -->
    <div class="top_advBox">
      <TopAdv/>
    </div>
    <!-- 导航下方图片背景 -->
    <div class="img_box">
      <img src="../../../assets/productImg/banner.jpg" alt=""/>
    </div>
    <div v-if="detailText" class="container">
      <!-- 首页->产品详情导航 -->
      <div class="product_adv">
        <div>
          <span @click="goIndex" class="return_page">首页</span><span>></span
        ><span class="return_page" @click="goProductPage">公司新闻</span>
        </div>
        <div class="right_time">
          <i class="el-icon-time"></i>
          <span class="time_text">{{ detailText.time }}</span>
        </div>
      </div>
      <!-- 右侧方块 -->
      <div class="product_icon" @click="goProductPage">
        <i class="el-icon-s-grid"></i>
      </div>
      <!-- 正文 -->
      <div v-loading='loading'>
        <h2>{{ detailText.name }}</h2>
        <div class="detail_text" v-html="detailText.details"></div>
<!--        <div class="detail_text" v-html="replaceImg(detailText.details)"></div>-->
      </div>
    </div>
    <BottomIntroduce></BottomIntroduce>
  </div>
</template>

<script>
import TopAdv from "../../../components/TopAdv";
import BottomIntroduce from "../../../components/BottomIntroduce";
import {getNewsList} from "../../../api/index";

export default {
  name: "newsDetail",
  data() {
    return {
      detailText: null,//详情数据
      loading: true,
    };
  },
  components: {
    TopAdv,
    BottomIntroduce,
  },
  created() {
    // 首页进入新闻导航变化
    this.$store.commit("modifyVuexAdvActive", {vuexAdvActive: 3});
    //   拿到id获取文章详情
    getNewsList(this.$route.query.id).then((res) => {
      this.loading = false
      this.detailText = res.data;
    });
  },
  methods: {
    replaceImg(data) {
      // data 为富文本的字符串内容，为了测试，只写了img标签
      let rule = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;// img 标签取src里面内容的正则
      let dataArr = data.match(rule);// 取到所有img标签 放到数组 dataArr里面
      if (dataArr) {
        for (let i = 0; i < dataArr.length; i++) {
          let srcImg = dataArr[i].replace(rule, '$1');//取src面的内容
          if (srcImg.slice(0, 4) === 'http') {
            //若src前4位置或者前5位是http、https则不做任何修改
            //console.log('不做任何修改');
            // a = a.replace(/src=['"]([^'"]+)[^>]*>/gi, 'src="' + baseUrl + srcImg + '"' + '>');
            data = data.replace(/http:\/\/admin.shangyuninfo.com/gi, "/api");
          }
        }
      }
      return data
    },
    //   返回首页
    goIndex() {
      this.$router.push({path: "/"});
    },
    // 点击详情返回产品页
    goProductPage() {
      this.$router.push({
        path: "/news",
      });
    },
  },
};
</script>

<style scoped lang="less">
.product_detil {
  min-width: 1200px;
}

.img_box {
  margin-top: 75px;

  img {
    width: 100%;
  }
}

.detail_text {
  color: #333 !important;
  line-height: 1.7;
  padding-bottom: 60px;

  &:deep(img) {
    width: 100%;
    border-radius: 6px;
    margin-top: 10px;
  }

  :deep(section) {
    box-sizing: border-box;
  }
}

.product_adv {
  padding: 20px 0;
  color: #8d8d8d;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #c8c8c8;
}

.product_icon {
  position: relative;
  height: 40px;

  i {
    position: absolute;
    right: 0;
    top: 30%;
    color: #999;
    font-size: 26px;
    cursor: pointer;
  }
}

h2 {
  text-align: center;
  margin-bottom: 30px;
}

.return_page {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.right_time {
  i {
    font-size: 18px;
  }

  .time_text {
    padding-left: 10px;
  }
}

.container {
  border-bottom: 1px solid #999;
  margin-bottom: 40px;
}
</style>
